<template>
	<div class="chat__body" id="chat__body">
		<chat-message
			@speak="speak"
			v-for="(msg, index) in msgs"
			:key="msg.id"
			:msg="msg"
			:prev="[index == 0 ? null : msgs[index - 1]]"
		>
		</chat-message>
	</div>
</template>

<script>
import ChatMessage from "@/components/ChatMessage";
let $this;
export default {
	components: {
		ChatMessage,
	},
	data() {
		return {
			voices: null,
			blockSpeech: false,
		};
	},
	created() {
		$this = this;
		this.loadVoices();
	},
	props: ["msgs"],
	methods: {
		speak(text) {
			if (text === "...") {
				return;
			}
			if (text.length > 200) {
				text = text.substring(0, 200);
			}
			if (!"speechSynthesis" in window) {
				return;
			}
			if (!this.voices || (this.voices && !this.voices.length)) {
				this.loadVoices();
			}
			/* if (this.blockSpeech) {
				return;
			} */
			this.blockSpeech = true;
			const uttr = new SpeechSynthesisUtterance();
			uttr.text = text;
			uttr.rate = 1;
			// If a voice has been selected, find the voice and set the
			// utterance instance's voice attribute.
			const voices = this.voices;
			for (let i = 0; i < voices.length; i++) {
				if (voices[i].name === "Google UK English Female") {
					uttr.voice = voices[i];
				}
			}
			window.speechSynthesis.cancel();
			window.speechSynthesis.speak(uttr);
			uttr.onend = function () {
				$this.blockSpeech = false;
				// hoge
			};
		},
		loadVoices() {
			this.voices = window.speechSynthesis.getVoices();
		},
	},
};
</script>

<style>
.chat__body {
	padding: 2rem;
	overflow: scroll;
	scroll-behavior: smooth;
}

.chat__body::-webkit-scrollbar {
	display: none;
}
</style>
