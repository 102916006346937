<template>
	<div class="chat">
		<div
			v-if="listening"
			style="
				height: 100%;
				background-color: black;
				opacity: 0.8;
				border-radius: 24px 24px 24px 24px;
				position: absolute;
				width: 100%;
			"
		>
			<!-- Ripple -->
			<svg viewBox="0 0 320 320">
				<defs>
					<circle id="circle-clip" cx="50%" cy="50%" r="25%" />
					<clipPath id="avatar-clip">
						<use href="#circle-clip" />
					</clipPath>
				</defs>

				<circle cx="50%" cy="50%" r="25%" fill="white" fill-opacity="1">
					<animate attributeName="r" values="25%;50%" dur="4s" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" values="1;0" dur="4s" repeatCount="indefinite" />
				</circle>

				<circle cx="50%" cy="50%" r="25%" fill="white" fill-opacity="1">
					<animate attributeName="r" values="25%;50%" dur="4s" begin="1s" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" values="1;0" dur="4s" begin="1s" repeatCount="indefinite" />
				</circle>

				<circle cx="50%" cy="50%" r="25%" fill="white" fill-opacity="1">
					<animate attributeName="r" values="25%;50%" dur="4s" begin="2s" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" values="1;0" dur="4s" begin="2s" repeatCount="indefinite" />
				</circle>

				<circle cx="50%" cy="50%" r="25%" fill="white" fill-opacity="1">
					<animate attributeName="r" values="25%;50%" dur="4s" begin="3s" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" values="1;0" dur="4s" begin="3s" repeatCount="indefinite" />
				</circle>

				<image height="50%" width="50%" x="25%" y="25%" :href="avatar" clip-path="url(#avatar-clip)" />
			</svg>
			<h1 style="color: white; text-align: center">I'm listening</h1>
			<p style="color: white; text-align: center">{{ text }}</p>
		</div>

		<div class="chat__header">
			<img style="height: 30px; margin: auto; display: block" src="../assets/tovie.png" />
		</div>
		<chat-list :msgs="msgData"></chat-list>
		<chat-form @voice="voice" @isListening="start" @submitMessage="sendMessage"></chat-form>
	</div>
</template>

<script>
import ChatList from "@/components/ChatList";
import ChatForm from "@/components/ChatForm";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export default {
	data() {
		return {
			userData: null,
			msgData: [],
			listening: false,
			sessionId: "",
			text: "",
			url: "",
			name: "",
			avatar: "",
		};
	},
	components: {
		ChatList,
		ChatForm,
	},
	computed: {},
	created() {},
	async mounted() {
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		this.url = params.url;
		this.sessionId = params.sessionId || uuidv4();
		this.name = params.name || "Bot Name";
		this.avatar = params.avatar || require("../assets/avatar.svg");
		await this.callBotProviderAPI("/start");
	},
	methods: {
		voice(text) {
			this.text = text;
		},
		start(state) {
			this.text = "";
			this.listening = state;
		},
		async sendMessage(msg) {
			this.msgData.push({
				from: {
					name: "DevplaCalledMe",
					avatar: "",
				},
				msg,
			});
			setTimeout(() => {
				const element = document.getElementById("chat__body");
				element.scrollTop = element.scrollHeight;
			}, 0);
			await this.callBotProviderAPI(msg);
			setTimeout(() => {
				const element = document.getElementById("chat__body");
				element.scrollTop = element.scrollHeight;
			}, 0);
		},
		async callBotProviderAPI(input) {
			this.addRepliesToTranscript([{ text: "..." }]);
			const response = await axios({
				method: "post",
				url: this.url, //"https://platform.tovie.ai/chatadapter/chatapi/maKbzFky:04fafb6955f0b40a414406235f53d9443ecfe7b4",
				data: { clientId: this.sessionId, query: input },
			});

			this.msgData.pop();

			this.addRepliesToTranscript(response.data.data.replies);
			this.welcomeMessageActioned = true;
		},
		addRepliesToTranscript(replies) {
			for (let i = 0; i < replies.length; i++) {
				const element = replies[i];
				this.msgData.push({
					id: uuidv4(),
					from: {
						name: this.name,
						avatar: "",
					},
					msg: element.text,
				});
			}
		},
	},
};
</script>

<style scoped>
.chat {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
}

.chat__header {
	background: #ffffff;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
	border-radius: 24px 24px 0px 0px;
	padding: 1.8rem;
	font-size: 16px;
	font-weight: 700;
}

.chat__header__greetings {
	color: #292929;
}

@media only screen and (max-width: 600px) {
	.chat {
		margin-top: 0px !important;
	}
}

#visuals {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
}

@media (orientation: landscape) {
	#visuals {
		height: 90vh;
		width: 90vh;
	}
}

@media (orientation: portrait) {
	#visuals {
		height: 90vw;
		width: 90vw;
	}
}
</style>
